<template>
    <div>
        <el-carousel arrow="never" trigger="click">
            <el-carousel-item v-for="(item, idx) in imgs" :key="idx">
                <img class="carousel-img" :src="item" />
            </el-carousel-item>
        </el-carousel>

        <!-- <b-carousel id="carousel-1" v-model="slide" :interval="3000" indicators>
            <b-carousel-slide v-for="(item, idx) in imgs" :key="idx">
                <template v-slot:img>
                    <img
                        class="d-block img-fluid w-100"
                        :src="item"
                        alt="image slot"
                    />
                </template>
            </b-carousel-slide>
        </b-carousel> -->
    </div>
</template>

<script>
export default {
    props: {
        imgs: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    data() {
        return {
            slide: 0
        };
    }
};
</script>

<style lang="less" scoped>
.carousel-img {
    width: 100%;
}

/deep/ .el-carousel__container {
    height: calc(100vw / 3841 * 1200);
}

@media (max-width: 1024px) {
    /deep/ .el-carousel__container {
        height: calc(100vw / 1080 * 1501);
    }
}
</style>
