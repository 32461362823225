<template>
    <div class="m-download">
        <div class="m-download-qrcode">
            <img
                src="@/assets/images/container/products/qrcode.png"
                class="m-download-qrcode__img"
            />
            <div class="m-download-qrcode__msg">扫二维码下载app</div>
        </div>

        <div class="m-download-btns">
            <a
                target="_blank"
                href="http://a.app.qq.com/o/simple.jsp?pkgname=com.kimoo.duduro"
            >
                <div class="m-download-btn"></div>
            </a>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.m-download {
    width: 100vw;
    height: calc(100vw / 1080 * 1451);
    background-image: url("../assets/images/mobile/container/products/banner.png");
    background-size: 100% 100%;
    background-position: 0 0;
    display: block;
    padding-top: calc(100vw / 1080 * 360);
    padding-left: 0;

    &-qrcode {
        width: calc(100vw / 1080 * 599);
        height: calc(100vw / 1080 * 719);
        background-image: url("../assets/images/mobile/container/products/download_bg.png");
        background-size: 100% 100%;
        background-position: 0 0;
        padding: calc(100vw / 1080 * 50) calc(100vw / 1080 * 55) 0
            calc(100vw / 1080 * 55);
        font-size: calc(100vw / 1080 * 60);
        margin: 0 auto;

        &__img {
            width: 100%;
        }
    }

    &-btns {
        margin: 0;
        text-align: center;

        a {
            width: calc(100vw / 1080 * 597);
            display: inline-block;
            margin: 0 auto;
        }
    }

    &-btn {
        width: calc(100vw / 1080 * 597);
        height: calc(100vw / 1080 * 177);
        background-size: 100% 100%;
        background-position: 0 0;
        cursor: pointer;

        background-image: url("../assets/images/mobile/container/products/btn_1.png");
        margin-top: calc(100vw / 1080 * 65);

        &:hover {
            background-image: url("../assets/images/mobile/container/products/btn_2.png");
        }
    }
}
</style>
