<template>
    <div>
        <MobileDownloadWays v-if="isMobile" />

        <template v-else>
            <div class="download">
                <div class="download-qrcode">
                    <img
                        src="@/assets/images/container/products/qrcode.png"
                        class="download-qrcode__img"
                    />
                    <div class="download-qrcode__msg">扫二维码下载app</div>
                </div>

                <div class="download-btns">
                    <div class="download-title">兜兜龙</div>
                    <div class="download-msg">小学生同步自学好助手</div>
                    <a
                        target="_blank"
                        href="https://itunes.apple.com/cn/app/qq/id1493475758"
                    >
                        <div class="download-btn download-btn__apple"></div>
                    </a>
                    <a
                        target="_blank"
                        href="https://a.app.qq.com/o/simple.jsp?pkgname=com.kimoo.duduro"
                    >
                        <div class="download-btn download-btn__android"></div>
                    </a>
                </div>
            </div>
        </template>

        <!-- <picture>
            <source
                media="(max-width: 1024px)"
                srcset="@/assets/images/mobile/container/products/content.png"
            />
            <source
                media="(min-width: 1025px)"
                srcset="@/assets/images/container/products/content.png"
            />
            <img
                class="content"
                src="@/assets/images/container/products/content.png"
            />
        </picture> -->

        <template v-if="isMobile">
            <img
                class="content"
                src="@/assets/images/mobile/container/products/content_1.jpg"
            />
            <img
                class="content"
                src="@/assets/images/mobile/container/products/content_2.jpg"
            />
            <img
                class="content"
                src="@/assets/images/mobile/container/products/content_3.jpg"
            />
        </template>
        <template v-else>
            <img
                class="content"
                src="@/assets/images/container/products/content.jpg"
            />
        </template>

        <div class="products-public-outer">
            <div class="products-public">
                <img
                    src="@/assets/images/public_qrcode.jpg"
                    class="products-public__qrcode"
                />
                <div class="products-public__msg">
                    <p>关注微信公众号</p>
                    <p>“兜兜龙同步学”</p>
                </div>
                <div class="products-public__title">获取更多学习内容</div>
            </div>
        </div>
    </div>
</template>

<script>
import MobileDownloadWays from "./mobile-download-ways";

export default {
    components: {
        MobileDownloadWays
    },

    data() {
        return {
            isMobile: this.$userAgent.isMobile
        };
    }
};
</script>

<style lang="less" scoped>
.download {
    width: 100vw;
    height: calc(100vw / 1920 * 600);
    // background-image: url("../assets/images/container/products/banner.png");
    background-size: 100% 100%;
    background-color: #fff;
    background-position: 0 0;
    padding-top: calc(100vw / 1920 * 80);
    padding-left: calc(100vw / 1920 * 530);
    display: flex;

    &-qrcode {
        width: calc(100vw / 1920 * 318);
        height: calc(100vw / 1920 * 378);
        background-image: url("../assets/images/container/products/download_bg.png");
        background-size: 100% 100%;
        background-position: 0 0;
        padding: calc(100vw / 1920 * 35) calc(100vw / 1920 * 40) 0
            calc(100vw / 1920 * 40);
        font-size: calc(100vw / 1920 * 28);
        color: #383838;
        text-align: center;

        &__img {
            width: 100%;
        }
    }

    &-btns {
        margin-top: calc(100vw / 1920 * 10);
        margin-left: calc(100vw / 1920 * 176);
        text-align: center;
    }

    &-btn {
        width: calc(100vw / 1920 * 336);
        height: calc(100vw / 1920 * 108);
        background-size: 100% 100%;
        background-position: 0 0;
        cursor: pointer;

        &__apple {
            background-image: url("../assets/images/container/products/btn_apple_1.png");
            margin-bottom: calc(100vw / 1920 * 15);

            &:hover {
                background-image: url("../assets/images/container/products/btn_apple_2.png");
            }
        }

        &__android {
            background-image: url("../assets/images/container/products/btn_android_1.png");

            &:hover {
                background-image: url("../assets/images/container/products/btn_android_2.png");
            }
        }
    }

    &-title {
        font-size: calc(100vw / 1920 * 48);
        color: #383838;
        line-height: 1;
    }

    &-msg {
        font-size: calc(100vw / 1920 * 24);
        color: #fbc73c;
        margin-top: calc(100vw / 1920 * 20);
        margin-bottom: calc(100vw / 1920 * 40);
        line-height: 1;
    }
}

.content {
    width: 100%;
}

.products-public {
    width: calc(100vw / 1920 * 260);
    height: calc(100vw / 1920 * 325);
    background-image: url("../assets/images/container/products/public_bg.png");
    background-size: 100% 100%;
    background-position: 0 0;
    font-size: calc(100vw / 1920 * 24);
    padding: calc(100vw / 1920 * 30);
    padding-bottom: 0;
    margin: 0 auto;

    position: relative;

    &__title {
        color: #383838;
        position: absolute;
        left: 50%;
        top: calc(100vw / 1920 * (-10));
        transform: translate(-50%, -100%);
        width: calc(100vw / 1920 * 240);
        text-align: center;
    }

    &__msg {
        color: #fff;

        p {
            margin-bottom: 0;
            text-align: center;
            line-height: 1.25;
        }
    }

    &__qrcode {
        width: 100%;
        background: #fff;
        margin-bottom: calc(100vw / 1920 * 20);
    }

    &-outer {
        background: #fff;
        padding: calc(100vw / 1920 * (70 + 24 + 10)) 0 calc(100vw / 1920 * 80);
    }
}

@media (max-width: 1024px) {
    .download {
        width: 100vw;
        height: calc(100vw / 1080 * 1781);
        background-image: url("../assets/images/mobile/container/products/banner.png");
        display: block;
        padding-top: calc(100vw / 1080 * 450);
        padding-left: 0;

        &-qrcode {
            width: calc(100vw / 1080 * 599);
            height: calc(100vw / 1080 * 719);
            background-image: url("../assets/images/mobile/container/products/download_bg.png");
            background-size: 100% 100%;
            background-position: 0 0;
            padding: calc(100vw / 1080 * 50) calc(100vw / 1080 * 55) 0
                calc(100vw / 1080 * 55);
            font-size: calc(100vw / 1080 * 60);
            margin: 0 auto;
        }

        &-btns {
            margin: 0;
            text-align: center;

            a {
                width: calc(100vw / 1080 * 597);
                display: inline-block;
                margin: 0 auto;
            }
        }

        &-btn {
            width: calc(100vw / 1080 * 597);
            height: calc(100vw / 1080 * 177);
            background-size: 100% 100%;
            background-position: 0 0;
            cursor: pointer;

            &__apple {
                background-image: url("../assets/images/mobile/container/products/btn_apple_1.png");
                margin-top: calc(100vw / 1080 * 85);
                margin-bottom: calc(100vw / 1080 * 50);

                &:hover {
                    background-image: url("../assets/images/mobile/container/products/btn_apple_2.png");
                }
            }

            &__android {
                background-image: url("../assets/images/mobile/container/products/btn_android_1.png");

                &:hover {
                    background-image: url("../assets/images/mobile/container/products/btn_android_2.png");
                }
            }
        }
    }

    .products-public {
        width: calc(100vw / 1080 * 376);
        height: calc(100vw / 1080 * 486);
        background-image: url("../assets/images/mobile/container/products/public_bg.png");
        background-size: 100% 100%;
        background-position: 0 0;
        font-size: calc(100vw / 1080 * 36);
        padding: calc(100vw / 1080 * 30);
        padding-bottom: 0;
        margin: calc(100vw / 1080 * 27) auto calc(100vw / 1080 * 140);
        position: relative;

        &__title {
            color: #383838;
            position: absolute;
            left: 50%;
            bottom: calc(100vw / 1080 * (-10));
            transform: translate(-50%, 100%);
            width: calc(100vw / 1080 * 376);
            text-align: center;
        }

        &__msg {
            color: #fff;

            p {
                margin-bottom: 0;
                text-align: center;
            }
        }

        &__qrcode {
            width: 100%;
            background: #fff;
            margin-bottom: calc(100vw / 1080 * 10);
        }
    }
}
</style>
