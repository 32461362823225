<template>
    <div class="g-page">
        <Nav />

        <BaseBanner :imgs="banners" />

        <Products />

        <Footer />

        <BackToTop />
    </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import BaseBanner from "@/components/base-banner.vue";
import Products from "@/components/products.vue";
import Footer from "@/components/footer.vue";
import BackToTop from "@/components/back-to-top.vue";

const banners = [
    // require("@/assets/images/container/products/zhapian2.png"),
    require("@/assets/images/container/products/banner_1.png"),
    require("@/assets/images/container/products/banner_2.png")
];

const MobileBanners = [
    require("@/assets/images/mobile/container/products/banner_1.png"),
    require("@/assets/images/mobile/container/products/banner_2.png")
];

export default {
    components: {
        Nav,
        BaseBanner,
        Products,
        Footer,
        BackToTop
    },

    data() {
        return {
            isMobile: this.$userAgent.isMobile,
            banners: this.$userAgent.isMobile ? MobileBanners : banners
        };
    },

    mounted() {}
};
</script>

<style lang="less" scoped>
.banner {
    width: 100%;
    margin-bottom: 80px;
}
</style>
